<script setup lang="ts"></script>

<template>
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20ZM6.11111 10C7.03159 10 7.77778 9.25381 7.77778 8.33333C7.77778 7.41286 7.03159 6.66667 6.11111 6.66667C5.19064 6.66667 4.44444 7.41286 4.44444 8.33333C4.44444 9.25381 5.19064 10 6.11111 10ZM13.8889 10C14.8094 10 15.5556 9.25381 15.5556 8.33333C15.5556 7.41286 14.8094 6.66667 13.8889 6.66667C12.9684 6.66667 12.2222 7.41286 12.2222 8.33333C12.2222 9.25381 12.9684 10 13.8889 10ZM5.87483 13.0141C5.54961 13.3393 5.54682 13.8714 5.91004 14.1536C6.37968 14.5184 6.89703 14.8195 7.44878 15.0481C8.25761 15.3831 9.12452 15.5556 10 15.5556C10.8755 15.5556 11.7424 15.3831 12.5512 15.0481C13.103 14.8195 13.6203 14.5184 14.09 14.1536C14.4532 13.8714 14.4504 13.3393 14.1252 13.0141C13.7999 12.6888 13.2758 12.6956 12.9012 12.9624C12.5945 13.1809 12.2634 13.3645 11.9138 13.5093C11.3071 13.7606 10.6567 13.89 10 13.89C9.34325 13.89 8.69293 13.7606 8.08618 13.5093C7.73665 13.3645 7.40551 13.1809 7.0988 12.9624C6.72416 12.6956 6.20006 12.6888 5.87483 13.0141Z"
            fill="url(#paint0_linear_308_479)"
        />
        <defs>
            <linearGradient
                id="paint0_linear_308_479"
                x1="10"
                y1="0"
                x2="10"
                y2="20"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#93A4BC" />
                <stop offset="1" stop-color="#5A6273" />
            </linearGradient>
        </defs>
    </svg>
</template>
